@tailwind base;
@tailwind components;
@tailwind utilities;
@import './variables.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: 'Nunito', sans-serif;
  font-size: var(--font-size-base);
  line-height: 1.5;
  background-color: var(--background-color);
  color: var(--text-color);
}

a {
  text-decoration: none;
  color: inherit;
}

ul, ol {
  list-style: none;
}

button {
  cursor: pointer;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-medium);
}

.header,
.footer {
  background-color: var(--primary-color);
  color: var(--text-color-light);
  padding: var(--spacing-small) 0;
  text-align: center;
}

.main {
  padding: var(--spacing-large);
  background-color: #ebe8e1;
}

.info-card-size {
  height: 500px;
}

/* .carousel .slide img {
  animation: zoom 15s infinite ease-in-out;
} */

@keyframes zoom {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

@keyframes infinite-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50% - 20px));
  }
}

.infinite-scroll {
  animation: infinite-scroll 5s linear infinite;
}

.dancing-script-font-800 {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 8000;
  font-style: normal;
}

.my-masonry-grid {
  display: flex;
  margin-left: -15px; 
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 15px; 
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 15px; 
}

@media print {
  img {
    visibility: hidden;
  }
}

img {
  cursor: pointer;
}

.hover-preview {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: none;
  border: 2px solid rgba(255, 255, 255, 0.8);
  z-index: 50;
}