:root {
    --primary-color: #3498db;
    --secondary-color: #2ecc71;
    --accent-color: #e74c3c;
    --background-color: #f9f9f9;
    --text-color: #333;
    --text-color-light: #fff;
  
    --font-family: 'Arial', sans-serif;
    --font-size-base: 16px;
    --font-size-small: 14px;
    --font-size-large: 18px;
    --font-weight-normal: 400;
    --font-weight-bold: 700;
  
    --spacing-small: 8px;
    --spacing-medium: 16px;
    --spacing-large: 24px;
    --spacing-xlarge: 32px;

    --border-radius: 4px;
    --border-width: 1px;
    --border-color: #ddd;
  }
  
  